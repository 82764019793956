import { useState, useEffect, useCallback } from 'react';
import { fetchApi } from '../lib/api/utils/fetch';
import {
  getStoredAuthState,
  setStoredAuthState,
  clearStoredAuthState,
} from '../lib/auth-store';
import { User } from '@/types/user';

interface LoginCredentials {
  email: string;
  password: string;
}

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean;
  token: string | null;
}

interface AuthResponse {
  success: boolean;
  message?: string;
  token: string;
  user: User;
}

const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.shipmate.fpsaus.com.au'
    : 'https://freight-forwarding-system.opdeeppc.workers.dev/api';

export function useAuth() {
  const [authState, setAuthState] = useState<AuthState>(() => {
    const storedState = getStoredAuthState();
    console.log('Initial auth state:', {
      isAuthenticated: storedState.isAuthenticated,
      hasUser: !!storedState.user,
      hasToken: !!storedState.token,
    });
    return {
      isAuthenticated: storedState.isAuthenticated,
      user: storedState.user,
      loading: !!storedState.token,
      token: storedState.token,
    };
  });

  const checkAuthStatus = useCallback(async () => {
    const token = authState.token;
    if (!token) {
      console.log('No token available for auth check');
      setAuthState(prev => ({ ...prev, loading: false }));
      return;
    }

    try {
      console.log('Checking auth status with token');
      const response = await fetchApi<{ success: boolean; user: User }>(
        '/auth/check',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Auth check response:', {
        success: response.success,
        hasUser: !!response.user,
      });

      if (response.success && response.user) {
        console.log('Auth check successful, updating state');
        setAuthState({
          isAuthenticated: true,
          user: response.user,
          loading: false,
          token,
        });

        // Update stored state
        setStoredAuthState({
          token,
          user: response.user,
          isAuthenticated: true,
        });
      } else {
        console.log('Auth check failed, clearing state');
        handleLogout();
      }
    } catch (error) {
      console.error('Auth check error:', error);
      handleLogout();
    }
  }, [authState.token]);

  useEffect(() => {
    if (authState.token && !authState.user) {
      console.log('Token exists but no user, checking auth status');
      checkAuthStatus();
    } else {
      console.log('No token or user already exists, skipping auth check');
      setAuthState(prev => ({ ...prev, loading: false }));
    }
  }, [authState.token, authState.user, checkAuthStatus]);

  const handleLogout = useCallback(() => {
    console.log('Logging out, clearing auth state');
    clearStoredAuthState();
    setAuthState({
      isAuthenticated: false,
      user: null,
      loading: false,
      token: null,
    });
  }, []);

  const login = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    try {
      console.log('Attempting login...');
      const response = await fetchApi<AuthResponse>('/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        skipAuth: true,
      });

      console.log('Login response:', {
        success: response.success,
        hasToken: !!response.token,
        hasUser: !!response.user,
      });

      if (response.success && response.token) {
        console.log('Login successful, updating auth state');
        setAuthState({
          isAuthenticated: true,
          user: response.user,
          loading: false,
          token: response.token,
        });

        // Update stored state
        setStoredAuthState({
          token: response.token,
          user: response.user,
          isAuthenticated: true,
        });
      }

      return response;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = useCallback(() => {
    console.log('Initiating logout...');
    handleLogout();
    window.location.href = '/login';
  }, [handleLogout]);

  return {
    ...authState,
    login,
    logout,
    checkAuthStatus,
  };
}
