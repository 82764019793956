import { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Loader2, Upload, Table } from 'lucide-react';
import { FileUploadArea } from './FileUploadArea';
import { useToast } from '@/components/ui/use-toast';
import {
  useTableExtraction,
  useRecentExtractions,
  downloadExtractionResult,
  useDeleteExtraction,
} from '@/lib/api/services/table-extraction';
import { ExtractionJob } from '@/lib/api/types/table-extraction';
import { useInterval } from '@/hooks/useInterval';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import { ExtractionsList } from '@/components/conversion/ExtractionsList';
import { useAuth } from '@/contexts/AuthContext';

const ITEMS_PER_PAGE = 15;

export function DocusamExtraction() {
  const { toast } = useToast();
  const { isAuthenticated } = useAuth();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [customColumns, setCustomColumns] = useState('');
  const [generatePreview, setGeneratePreview] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeletingId, setIsDeletingId] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const extractionMutation = useTableExtraction();
  const deleteMutation = useDeleteExtraction();
  const {
    data: recentExtractions,
    isLoading: isRecentLoading,
    refetch: refreshExtractions,
  } = useRecentExtractions(ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE);

  useEffect(() => {
    if (recentExtractions) {
      console.log('Recent Extractions Data:', recentExtractions);
    }
  }, [recentExtractions]);

  // Poll for updates while there are pending or processing extractions
  useInterval(() => {
    if (
      refreshExtractions &&
      recentExtractions?.jobs.some(
        job => job.status === 'pending' || job.status === 'processing'
      )
    ) {
      refreshExtractions();
    }
  }, 5000);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleFileSelect = (files: File[]) => {
    const validFiles = files.filter(file => {
      const validTypes = [
        'image/png',
        'image/jpeg',
        'image/tiff',
        'application/pdf',
      ];
      if (!validTypes.includes(file.type)) {
        toast({
          title: 'Invalid file type',
          description: `${file.name}: Please upload only PNG, JPEG, TIFF, or PDF files`,
          variant: 'destructive',
        });
        return false;
      }
      return true;
    });
    setSelectedFiles(validFiles);
  };

  const handleUpload = async () => {
    if (!isAuthenticated) {
      toast({
        title: 'Authentication required',
        description: 'Please log in to upload files',
        variant: 'destructive',
      });
      return;
    }

    if (selectedFiles.length === 0) {
      toast({
        title: 'Missing files',
        description: 'Please select files to process',
        variant: 'destructive',
      });
      return;
    }

    setIsUploading(true);
    try {
      const formData = new FormData();
      selectedFiles.forEach(file => {
        formData.append('files', file);
      });

      // Add extraction options
      const options = {
        generatePreview,
        customColumns: customColumns
          .split(',')
          .map(col => col.trim())
          .filter(col => col.length > 0),
        validationConfig: {
          minRows: 1,
          minCols: 1,
          maxEmptyCells: 10,
          minConfidence: 0.7,
        },
      };

      // Append each option individually to match Zod schema
      formData.append('generatePreview', generatePreview ? '1' : '0');

      // Properly append custom columns as an array
      if (options.customColumns.length > 0) {
        options.customColumns.forEach((column, index) => {
          formData.append(`customColumns[]`, column);
        });
      }

      // Properly append validation config as individual fields
      if (options.validationConfig) {
        formData.append(
          'validationConfig[minRows]',
          options.validationConfig.minRows.toString()
        );
        formData.append(
          'validationConfig[minCols]',
          options.validationConfig.minCols.toString()
        );
        formData.append(
          'validationConfig[maxEmptyCells]',
          options.validationConfig.maxEmptyCells.toString()
        );
        formData.append(
          'validationConfig[minConfidence]',
          options.validationConfig.minConfidence.toString()
        );
      }

      const response = await extractionMutation.mutateAsync(formData);

      if (response.success) {
        toast({
          title: 'Upload successful',
          description: 'Your files have been queued for table extraction',
        });
        setSelectedFiles([]);
        setCurrentPage(1);
        refreshExtractions();
      } else {
        throw new Error(response.error || 'Failed to upload files');
      }
    } catch (error) {
      toast({
        title: 'Upload failed',
        description:
          error instanceof Error ? error.message : 'Failed to upload files',
        variant: 'destructive',
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleDownload = async (jobId: string) => {
    if (!isAuthenticated) {
      toast({
        title: 'Authentication required',
        description: 'Please log in to download files',
        variant: 'destructive',
      });
      return;
    }

    setIsDownloading(true);
    try {
      await downloadExtractionResult(jobId);
      toast({
        title: 'Download started',
        description: 'Your file will be downloaded shortly',
      });
    } catch (error) {
      toast({
        title: 'Download failed',
        description:
          error instanceof Error ? error.message : 'Failed to download file',
        variant: 'destructive',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDelete = async (jobId: string) => {
    if (!isAuthenticated) {
      toast({
        title: 'Authentication required',
        description: 'Please log in to delete files',
        variant: 'destructive',
      });
      return;
    }

    setIsDeletingId(jobId);
    try {
      await deleteMutation.mutateAsync(jobId);
      toast({
        title: 'Extraction deleted',
        description: 'The extraction has been removed',
      });
      refreshExtractions();
    } catch (error) {
      toast({
        title: 'Delete failed',
        description:
          error instanceof Error
            ? error.message
            : 'Failed to delete extraction',
        variant: 'destructive',
      });
    } finally {
      setIsDeletingId(null);
    }
  };

  return (
    <Card className="mt-8">
      <CardHeader>
        <CardTitle>
          <div className="flex items-center space-x-2">
            <Table className="h-6 w-6" />
            <span>Docusam Table Extraction</span>
          </div>
        </CardTitle>
        <CardDescription>
          Extract tables from documents and convert them to Excel format
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-4">
          <div>
            <Label htmlFor="customColumns">
              Custom Columns (comma-separated)
            </Label>
            <Textarea
              id="customColumns"
              placeholder="Enter column names separated by commas (e.g., Description, Quantity, Price)"
              value={customColumns}
              onChange={e => setCustomColumns(e.target.value)}
              className="mt-1"
            />
          </div>

          <div className="flex items-center space-x-2">
            <Switch
              id="preview"
              checked={generatePreview}
              onCheckedChange={setGeneratePreview}
            />
            <Label htmlFor="preview">Generate table preview</Label>
          </div>

          <FileUploadArea
            selectedFiles={selectedFiles}
            isUploading={isUploading}
            onFileSelect={handleFileSelect}
            accept=".png,.jpg,.jpeg,.tiff,.pdf"
          />

          <Button
            onClick={handleUpload}
            disabled={selectedFiles.length === 0 || isUploading}
            className="w-full"
          >
            {isUploading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processing...
              </>
            ) : (
              <>
                <Table className="mr-2 h-4 w-4" />
                Extract Tables{' '}
                {selectedFiles.length > 0 &&
                  `(${selectedFiles.length} file${
                    selectedFiles.length !== 1 ? 's' : ''
                  })`}
              </>
            )}
          </Button>
        </div>

        <div className="mt-8">
          <h3 className="mb-4 text-lg font-medium">Recent Extractions</h3>
          <ExtractionsList
            jobs={recentExtractions?.jobs || []}
            isLoading={isRecentLoading}
            isDownloading={isDownloading}
            isDeletingId={isDeletingId}
            onDownload={handleDownload}
            onDelete={handleDelete}
            currentPage={currentPage}
            totalItems={recentExtractions?.total || 0}
            itemsPerPage={ITEMS_PER_PAGE}
            onPageChange={handlePageChange}
          />
        </div>
      </CardContent>
    </Card>
  );
}
