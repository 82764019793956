import { useState, useEffect, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useToast } from '@/components/ui/use-toast';
import { useApi } from '@/lib/api/hooks/useApi';
import { useAuth } from '@/hooks/useAuth';
import { downloadFile } from '@/lib/api/utils/fetch';

interface BulkUploadFormValues {
  origin: string;
  destination: string;
  company_name: string;
  industry: string;
  sector: string;
  hs_digits: string;
}

export interface BulkUploadJob {
  id: string;
  status: 'pending' | 'processing' | 'completed' | 'failed';
  total_rows: number;
  processed_rows: number;
  error_message?: string | null;
  download_url?: string;
  created_at: string;
  updated_at: string;
  original_filename: string;
  user_id: string;
  upload_key?: string;
  result_key?: string;
  origin?: string;
  destination?: string;
  company_name?: string;
  industry?: string;
  sector?: string;
  hs_digits?: string;
}

interface BulkJobsResponse {
  success: boolean;
  jobs: BulkUploadJob[];
  error?: string;
}

export function useBulkUpload(defaultHsDigits: string) {
  const { toast } = useToast();
  const api = useApi();
  const { isAuthenticated } = useAuth();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isBulkLoading, setIsBulkLoading] = useState(false);
  const [recentBulkUploads, setRecentBulkUploads] = useState<BulkUploadJob[]>(
    []
  );

  const bulkUploadForm = useForm<BulkUploadFormValues>({
    defaultValues: {
      origin: '',
      destination: '',
      company_name: '',
      industry: '',
      sector: '',
      hs_digits: defaultHsDigits || '8',
    },
  });

  // Refs for stable polling
  const pollingIntervalRef = useRef<NodeJS.Timeout>();
  const isPollingRef = useRef(false);
  const mountedRef = useRef(true);

  // Stable callback that doesn't depend on state
  const refreshBulkUploads = useCallback(async () => {
    if (!isAuthenticated || !mountedRef.current) return;

    try {
      console.log('Refreshing bulk uploads');
      const jobs = await api.get<BulkUploadJob[]>('/hs-lookup/bulk/jobs');

      if (!mountedRef.current) return;

      if (!Array.isArray(jobs)) {
        console.error('Expected array response but got:', typeof jobs);
        return;
      }

      console.log('Fetched jobs:', jobs.length, 'jobs');

      // Only update state if mounted and jobs have changed
      if (mountedRef.current) {
        setRecentBulkUploads(prevJobs => {
          const prevJobsStr = JSON.stringify(prevJobs);
          const newJobsStr = JSON.stringify(jobs);

          if (prevJobsStr !== newJobsStr) {
            console.log('Jobs changed, updating state with:', jobs);
            return jobs;
          }
          return prevJobs;
        });
      }

      // Check for pending jobs
      const hasPendingJobs = jobs.some(
        job => job.status === 'pending' || job.status === 'processing'
      );

      // Update polling state
      if (!hasPendingJobs && pollingIntervalRef.current) {
        console.log('No pending jobs, stopping polling');
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = undefined;
        isPollingRef.current = false;
      } else if (hasPendingJobs) {
        console.log('Has pending jobs, continuing polling');
      }

      return jobs;
    } catch (error) {
      console.error('Failed to fetch recent uploads:', error);
      // Stop polling on error
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = undefined;
        isPollingRef.current = false;
      }

      toast({
        title: 'Error',
        description: 'Failed to fetch recent uploads',
        variant: 'destructive',
      });
      return [];
    }
  }, [api, isAuthenticated, toast]);

  // Set up polling
  useEffect(() => {
    mountedRef.current = true;

    const startPolling = async () => {
      if (!isAuthenticated || isPollingRef.current) return;

      try {
        console.log('Starting initial jobs fetch');
        const jobs = await api.get<BulkUploadJob[]>('/hs-lookup/bulk/jobs');

        if (!mountedRef.current) return;

        console.log('Initial jobs fetch:', jobs.length, 'jobs');

        // Update state only if jobs have changed
        setRecentBulkUploads(prevJobs => {
          if (JSON.stringify(prevJobs) !== JSON.stringify(jobs)) {
            return jobs;
          }
          return prevJobs;
        });

        // Only start polling if there are actually pending or processing jobs
        const hasPendingJobs = jobs.some(
          job => job.status === 'pending' || job.status === 'processing'
        );

        if (hasPendingJobs && !pollingIntervalRef.current) {
          console.log('Starting polling - found pending jobs');
          isPollingRef.current = true;
          pollingIntervalRef.current = setInterval(refreshBulkUploads, 5000);
        } else if (!hasPendingJobs && pollingIntervalRef.current) {
          console.log('Stopping polling - no pending jobs');
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = undefined;
          isPollingRef.current = false;
        }
      } catch (error) {
        console.error('Error starting polling:', error);
        if (pollingIntervalRef.current) {
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = undefined;
          isPollingRef.current = false;
        }
      }
    };

    // Initial check for pending jobs
    startPolling();

    // Cleanup function
    return () => {
      console.log('Cleaning up polling');
      mountedRef.current = false;
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = undefined;
      }
      isPollingRef.current = false;
    };
  }, [isAuthenticated, api, refreshBulkUploads]);

  // Update form defaults when hsDigits changes
  useEffect(() => {
    if (defaultHsDigits) {
      bulkUploadForm.setValue('hs_digits', defaultHsDigits);
    }
  }, [defaultHsDigits, bulkUploadForm]);

  const handleFileSelect = (file: File | null) => {
    setSelectedFile(file);
  };

  const handleBulkUpload = async () => {
    if (!isAuthenticated) {
      toast({
        title: 'Authentication required',
        description: 'Please log in to use the bulk upload feature',
        variant: 'destructive',
      });
      return;
    }

    if (!selectedFile) {
      toast({
        title: 'No file selected',
        description: 'Please select a file to upload',
        variant: 'destructive',
      });
      return;
    }

    setIsBulkLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Get all form values
      const formValues = bulkUploadForm.getValues();

      // Add non-empty form values to FormData
      Object.entries(formValues).forEach(([key, value]) => {
        if (value && value.trim() !== '') {
          formData.append(key, value.trim());
        }
      });

      const response = await api.post<{
        success: boolean;
        job_id: string;
        total_rows: number;
        error?: string;
      }>('/hs-lookup/bulk', formData);

      if (!response.success) {
        throw new Error(response.error || 'Failed to upload file');
      }

      toast({
        title: 'Upload successful',
        description: `Processing ${response.total_rows} rows. Job ID: ${response.job_id}`,
      });

      // Reset file selection
      setSelectedFile(null);

      // Refresh jobs and start polling if needed
      const jobs = await refreshBulkUploads();

      if (
        jobs?.some(
          job => job.status === 'pending' || job.status === 'processing'
        ) &&
        !isPollingRef.current
      ) {
        isPollingRef.current = true;
        pollingIntervalRef.current = setInterval(refreshBulkUploads, 5000);
      }
    } catch (error) {
      console.error('Bulk upload error:', error);
      toast({
        title: 'Upload failed',
        description:
          error instanceof Error ? error.message : 'Failed to upload file',
        variant: 'destructive',
      });
    } finally {
      setIsBulkLoading(false);
    }
  };

  const downloadBulkResult = async (jobId: string) => {
    try {
      const { blob, filename } = await downloadFile(
        `/hs-lookup/bulk/download/${jobId}`
      );

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast({
        title: 'Download successful',
        description: 'The file has been downloaded successfully.',
      });
    } catch (error) {
      console.error('Failed to download result:', error);
      toast({
        title: 'Download failed',
        description:
          error instanceof Error ? error.message : 'Failed to download file',
        variant: 'destructive',
      });
    }
  };

  const deleteBulkJob = async (jobId: string) => {
    try {
      const response = await api
        .delete<{
          success: boolean;
          error?: string;
          message?: string;
        }>(`/hs-lookup/bulk/${jobId}`)
        .catch(error => {
          // Handle 404 and other HTTP errors
          if (error.message.includes('404')) {
            throw new Error('Job not found or already deleted');
          }
          throw error;
        });

      if (!response.success) {
        throw new Error(response.error || 'Failed to delete job');
      }

      // Refresh the list after successful deletion
      await refreshBulkUploads();

      toast({
        title: 'Job deleted',
        description:
          response.message || 'The job has been deleted successfully.',
      });
    } catch (error) {
      console.error('Failed to delete job:', error);

      // Show more specific error message
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to delete job';

      toast({
        title: 'Delete failed',
        description: errorMessage,
        variant: 'destructive',
      });

      // Refresh the list to ensure UI is in sync
      await refreshBulkUploads();
    }
  };

  return {
    selectedFile,
    isBulkLoading,
    recentBulkUploads,
    handleFileSelect,
    handleBulkUpload,
    refreshBulkUploads,
    bulkUploadForm,
    downloadBulkResult,
    deleteBulkJob,
  };
}
