import { useState } from 'react';
import { useToast } from '@/hooks/use-toast';
import { fetchApi } from '@/lib/api/utils/fetch';
import {
  User,
  UserFormData,
  UsersResponse,
  UserUpdateData,
  PasswordUpdateData,
} from '@/types/user';

export function useUserManagement() {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  const fetchUsers = async () => {
    try {
      const data = await fetchApi<UsersResponse>('users');
      setUsers(data.users || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch users',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addUser = async (userData: UserFormData) => {
    try {
      await fetchApi('users', {
        method: 'POST',
        body: JSON.stringify({
          ...userData,
          permissions:
            userData.permissions.length > 0 ? userData.permissions : null,
        }),
      });

      await fetchUsers();
      toast({
        title: 'Success',
        description: 'User added successfully',
      });
      return true;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to add user',
        variant: 'destructive',
      });
      return false;
    }
  };

  const updateUser = async (userData: UserUpdateData) => {
    try {
      // Ensure permissions is sent as an array or null
      const permissions =
        Array.isArray(userData.permissions) && userData.permissions.length > 0
          ? userData.permissions
          : null;

      await fetchApi(`users/${userData.id}`, {
        method: 'PATCH',
        body: JSON.stringify({
          ...userData,
          permissions,
        }),
      });

      await fetchUsers();
      toast({
        title: 'Success',
        description: 'User updated successfully',
      });
      return true;
    } catch (error) {
      console.error('Error updating user:', error);
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to update user',
        variant: 'destructive',
      });
      return false;
    }
  };

  const updatePassword = async (id: string, password: string) => {
    try {
      await fetchApi(`users/${id}/password`, {
        method: 'PUT',
        body: JSON.stringify({ password }),
      });

      toast({
        title: 'Success',
        description: 'Password updated successfully',
      });
      return true;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update password',
        variant: 'destructive',
      });
      return false;
    }
  };

  const deleteUser = async (id: string) => {
    try {
      await fetchApi(`users/${id}`, {
        method: 'DELETE',
      });

      await fetchUsers();
      toast({
        title: 'Success',
        description: 'User deleted successfully',
      });
      return true;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete user',
        variant: 'destructive',
      });
      return false;
    }
  };

  return {
    users,
    isLoading,
    fetchUsers,
    addUser,
    updateUser,
    updatePassword,
    deleteUser,
  };
}
