import { useState } from 'react';
import { useToast } from '@/components/ui/use-toast';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Upload, Loader2 } from 'lucide-react';
import { FileUploadArea } from '@/components/conversion/FileUploadArea';
import { ConversionsList } from '@/components/conversion/ConversionsList';
import { DocusamExtraction } from '@/components/conversion/DocusamExtraction';
import { isValidFileType } from '@/utils/conversion';
import {
  useUploadForConversion,
  useRecentConversions,
  downloadConversionResult,
  useDeleteConversion,
} from '@/lib/api/services/conversion';
import { ConversionJob } from '@/lib/api/types/conversion';
import { useInterval } from '@/hooks/useInterval';

// Conversion states
type ConversionState = 'pending' | 'processing' | 'completed' | 'failed';

const ITEMS_PER_PAGE = 15;

export default function Convert() {
  const { toast } = useToast();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeletingId, setIsDeletingId] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const uploadMutation = useUploadForConversion();
  const deleteMutation = useDeleteConversion();
  const {
    data: recentConversions,
    isLoading: isRecentLoading,
    refetch: refreshConversions,
  } = useRecentConversions(ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE);

  // Poll for updates every 5 seconds while there are pending or processing conversions
  useInterval(() => {
    if (
      refreshConversions &&
      recentConversions?.jobs.some(
        (job: ConversionJob) =>
          job.status === 'pending' || job.status === 'processing'
      )
    ) {
      refreshConversions();
    }
  }, 5000);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleFileSelect = (files: File[]) => {
    const validFiles = files.filter(file => {
      if (!isValidFileType(file.type)) {
        toast({
          title: 'Invalid file type',
          description: `${file.name}: Please upload only PNG, JPEG, HEIC, TIFF, or PDF files`,
          variant: 'destructive',
        });
        return false;
      }
      return true;
    });
    setSelectedFiles(validFiles);
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      toast({
        title: 'Missing files',
        description: 'Please select files to convert',
        variant: 'destructive',
      });
      return;
    }

    setIsUploading(true);
    try {
      const response = await uploadMutation.mutateAsync({
        files: selectedFiles,
      });

      if (response.success) {
        toast({
          title: 'Upload successful',
          description:
            response.message || 'Your files have been queued for processing',
        });
        setSelectedFiles([]);
        setCurrentPage(1); // Reset to first page after upload
        refreshConversions();
      } else {
        throw new Error(response.error || 'Failed to upload files');
      }
    } catch (error) {
      toast({
        title: 'Upload failed',
        description:
          error instanceof Error ? error.message : 'Failed to upload files',
        variant: 'destructive',
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleDownload = async (jobId: string) => {
    setIsDownloading(true);
    try {
      await downloadConversionResult(jobId);
      toast({
        title: 'Download started',
        description: 'Your file will be downloaded shortly',
      });
    } catch (error) {
      toast({
        title: 'Download failed',
        description:
          error instanceof Error ? error.message : 'Failed to download file',
        variant: 'destructive',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDelete = async (jobId: string) => {
    setIsDeletingId(jobId);
    try {
      await deleteMutation.mutateAsync(jobId);
      toast({
        title: 'Conversion deleted',
        description: 'The conversion has been removed',
      });
      refreshConversions();
    } catch (error) {
      toast({
        title: 'Delete failed',
        description:
          error instanceof Error
            ? error.message
            : 'Failed to delete conversion',
        variant: 'destructive',
      });
    } finally {
      setIsDeletingId(null);
    }
  };

  const getConversionStatus = (job: ConversionJob) => {
    if (job.status === 'completed') {
      if (job.error) {
        job.error = '';
      }
      return 'Completed';
    }
    if (job.status === 'failed') return 'Failed';
    if (job.status === 'processing') return 'Processing';
    if (job.status === 'pending') return 'Pending';
    return job.displayStatus || 'Unknown';
  };

  return (
    <div className="container mx-auto p-4 space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>Convert Documents</CardTitle>
          <CardDescription>
            Upload documents to convert them to Excel format
          </CardDescription>
        </CardHeader>
        <CardContent>
          <FileUploadArea
            selectedFiles={selectedFiles}
            isUploading={isUploading}
            onFileSelect={handleFileSelect}
          />

          <Button
            onClick={handleUpload}
            disabled={selectedFiles.length === 0 || isUploading}
            className="w-full mt-4"
          >
            {isUploading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Uploading...
              </>
            ) : (
              <>
                <Upload className="mr-2 h-4 w-4" />
                Upload and Queue{' '}
                {selectedFiles.length > 0 &&
                  `(${selectedFiles.length} file${
                    selectedFiles.length !== 1 ? 's' : ''
                  })`}
              </>
            )}
          </Button>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Recent Conversions</CardTitle>
        </CardHeader>
        <CardContent>
          <ConversionsList
            jobs={
              recentConversions?.jobs.map((job: ConversionJob) => ({
                ...job,
                displayStatus: getConversionStatus(job),
                canDownload: job.status === 'completed',
                isProcessing: job.status === 'processing',
                isPending: job.status === 'pending',
              })) || []
            }
            isLoading={isRecentLoading}
            isDownloading={isDownloading}
            isDeletingId={isDeletingId}
            onDownload={handleDownload}
            onDelete={handleDelete}
            currentPage={currentPage}
            totalItems={recentConversions?.total || 0}
            itemsPerPage={ITEMS_PER_PAGE}
            onPageChange={handlePageChange}
          />
        </CardContent>
      </Card>

      {/* Add Docusam Table Extraction */}
      <DocusamExtraction />
    </div>
  );
}
