import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Checkbox } from '@/components/ui/checkbox';
import { Edit } from 'lucide-react';
import { User, UserUpdateData, USER_ROLES, UserRole } from '@/types/user';
import { PERMISSIONS, Permission, formatPermission } from '@/lib/permissions';

interface EditUserDialogProps {
  user: User;
  onUpdateUser: (userData: UserUpdateData) => Promise<boolean>;
  onUpdatePassword: (id: string, password: string) => Promise<boolean>;
}

export function EditUserDialog({
  user,
  onUpdateUser,
  onUpdatePassword,
}: EditUserDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [formData, setFormData] = useState<UserUpdateData>({
    id: parseInt(user.id),
    username: user.username,
    email: user.email,
    role: user.role as UserRole,
    name: user.name || '',
    permissions: [],
  });
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    // Parse permissions when dialog opens
    if (user.permissions) {
      if (Array.isArray(user.permissions)) {
        setFormData(prev => ({
          ...prev,
          permissions: user.permissions as Permission[],
        }));
      } else {
        try {
          const parsedPermissions = JSON.parse(
            user.permissions
          ) as Permission[];
          setFormData(prev => ({ ...prev, permissions: parsedPermissions }));
        } catch {
          setFormData(prev => ({ ...prev, permissions: [] }));
        }
      }
    }
  }, [user.permissions]);

  const handleInputChange = (
    field: keyof Omit<UserUpdateData, 'id'>,
    value: string | Permission[]
  ) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handlePermissionChange = (permission: Permission) => {
    setFormData(prev => ({
      ...prev,
      permissions: prev.permissions.includes(permission)
        ? prev.permissions.filter(p => p !== permission)
        : [...prev.permissions, permission],
    }));
  };

  const handleSubmit = async () => {
    const success = await onUpdateUser(formData);
    if (success) {
      setIsOpen(false);
    }
  };

  const handlePasswordChange = async () => {
    const success = await onUpdatePassword(user.id, newPassword);
    if (success) {
      setNewPassword('');
      setShowPasswordChange(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <Edit className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle>Edit User</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="grid gap-2">
            <Label>Username</Label>
            <Input
              placeholder="Enter username"
              value={formData.username}
              onChange={e => handleInputChange('username', e.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <Label>Email</Label>
            <Input
              type="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={e => handleInputChange('email', e.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <Label>Name</Label>
            <Input
              placeholder="Enter name"
              value={formData.name}
              onChange={e => handleInputChange('name', e.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <Label>Role</Label>
            <Select
              value={formData.role}
              onValueChange={value => handleInputChange('role', value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select role" />
              </SelectTrigger>
              <SelectContent>
                {USER_ROLES.map(role => (
                  <SelectItem key={role} value={role}>
                    {role.charAt(0).toUpperCase() + role.slice(1)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="grid gap-2">
            <Label>Permissions</Label>
            <div className="grid grid-cols-2 gap-2">
              {Object.values(PERMISSIONS).map(permission => (
                <div key={permission} className="flex items-center space-x-2">
                  <Checkbox
                    id={permission}
                    checked={formData.permissions.includes(permission)}
                    onCheckedChange={() => handlePermissionChange(permission)}
                  />
                  <label
                    htmlFor={permission}
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {formatPermission(permission)}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => setShowPasswordChange(!showPasswordChange)}
            >
              {showPasswordChange
                ? 'Cancel Password Change'
                : 'Change Password'}
            </Button>
            {showPasswordChange && (
              <div className="space-y-2">
                <Input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />
                <Button type="button" onClick={handlePasswordChange}>
                  Update Password
                </Button>
              </div>
            )}
          </div>
          <Button className="w-full" onClick={handleSubmit}>
            Update User
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
