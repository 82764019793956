import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  ExtractionJob,
  ExtractionsListResponse,
  ExtractionResponse,
  TableExtractionResponse,
  TableExtractionRecord,
} from '@/lib/api/types/table-extraction';
import { getAuthToken } from '../../auth-store';
import { getApiUrl } from '@/lib/config';

// Use the API URL from config
const API_BASE = getApiUrl('table-extraction');

export const useTableExtraction = () => {
  return useMutation<ExtractionResponse, Error, FormData>({
    mutationFn: async (formData: FormData) => {
      const token = getAuthToken();
      if (!token) {
        throw new Error('Not authenticated');
      }

      const response = await fetch(`${API_BASE}/extract`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to process files');
      }

      return response.json();
    },
  });
};

export const useRecentExtractions = (limit: number, offset: number) => {
  return useQuery({
    queryKey: ['extractions', { limit, offset }] as const,
    queryFn: async ({ queryKey: [_, { limit, offset }] }) => {
      const token = getAuthToken();
      if (!token) {
        throw new Error('Not authenticated');
      }

      const response = await fetch(
        `${API_BASE}/list?limit=${limit}&offset=${offset}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to fetch extractions');
      }

      const data = await response.json();
      console.log('Raw API Response:', data);

      // Transform the response data to match ExtractionJob interface
      const jobs = data.jobs.map((job: TableExtractionRecord) => ({
        id: job.id.toString(),
        userId: job.user_id.toString(),
        fileName: job.file_name,
        fileType: job.file_type,
        status: job.status,
        tableCount: job.table_count,
        confidence: job.confidence,
        processingTime: job.processing_time,
        errorMessage: job.error_message,
        outputUrl: job.output_url,
        previewUrl: job.preview_url || null,
        createdAt: job.created_at,
        updatedAt: job.updated_at,
      }));

      return {
        jobs,
        total: data.total,
      } as ExtractionsListResponse;
    },
    refetchInterval: (data: ExtractionsListResponse | undefined) => {
      // Refetch every 5 seconds if there are pending or processing jobs
      if (
        data?.jobs.some(
          job => job.status === 'pending' || job.status === 'processing'
        )
      ) {
        return 5000;
      }
      return false;
    },
  });
};

export const useDeleteExtraction = () => {
  return useMutation<{ success: boolean }, Error, string>({
    mutationFn: async (id: string) => {
      const token = getAuthToken();
      if (!token) {
        throw new Error('Not authenticated');
      }

      const response = await fetch(`${API_BASE}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to delete extraction');
      }

      return response.json();
    },
  });
};

export const downloadExtractionResult = async (id: string): Promise<void> => {
  const token = getAuthToken();
  if (!token) {
    throw new Error('Not authenticated');
  }

  const response = await fetch(getApiUrl(`table-extraction/${id}/download`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to download extraction result');
  }

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `extraction-${id}.xlsx`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
