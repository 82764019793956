import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload } from 'lucide-react';
import { cn } from '@/lib/utils';

export interface FileUploadAreaProps {
  selectedFiles: File[];
  isUploading: boolean;
  onFileSelect: (files: File[]) => void;
  accept?: string;
}

export function FileUploadArea({
  selectedFiles,
  isUploading,
  onFileSelect,
  accept,
}: FileUploadAreaProps) {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFileSelect(acceptedFiles);
    },
    [onFileSelect]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: isUploading,
    accept: accept
      ? accept.split(',').reduce(
          (acc, type) => {
            acc[type] = [];
            return acc;
          },
          {} as Record<string, string[]>
        )
      : undefined,
  });

  return (
    <div
      {...getRootProps()}
      className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-colors ${
        isDragActive
          ? 'border-primary bg-primary/5'
          : 'border-muted-foreground/25 hover:border-primary'
      } ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      <input {...getInputProps()} />
      <div className="flex flex-col items-center gap-2">
        <Upload className="h-8 w-8 text-muted-foreground" />
        <div className="text-sm text-muted-foreground">
          {selectedFiles.length > 0 ? (
            <div className="space-y-1">
              <p>Selected files:</p>
              {selectedFiles.map((file, index) => (
                <p key={index} className="font-medium text-foreground">
                  {file.name}
                </p>
              ))}
            </div>
          ) : isDragActive ? (
            <p>Drop the files here...</p>
          ) : (
            <p>
              Drag & drop files here, or click to select files
              {accept && <span className="block text-xs">{accept}</span>}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
