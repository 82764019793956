import { ExtractionJob } from '@/lib/api/types/table-extraction';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Loader2, Download, Trash2, Eye } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { SimplePagination } from '@/components/ui/pagination';
import { formatDistanceToNow } from 'date-fns';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useState } from 'react';

interface ExtractionsListProps {
  jobs: ExtractionJob[];
  isLoading: boolean;
  isDownloading: boolean;
  isDeletingId: string | null;
  onDownload: (id: string) => void;
  onDelete: (id: string) => void;
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

export function ExtractionsList({
  jobs,
  isLoading,
  isDownloading,
  isDeletingId,
  onDownload,
  onDelete,
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}: ExtractionsListProps) {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const getStatusBadge = (status: ExtractionJob['status']) => {
    switch (status) {
      case 'completed':
        return (
          <Badge variant="success" className="capitalize">
            {status}
          </Badge>
        );
      case 'processing':
        return (
          <Badge variant="warning" className="capitalize">
            {status}
          </Badge>
        );
      case 'failed':
        return (
          <Badge variant="destructive" className="capitalize">
            {status}
          </Badge>
        );
      default:
        return (
          <Badge variant="secondary" className="capitalize">
            {status}
          </Badge>
        );
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (!jobs.length) {
    return (
      <div className="flex items-center justify-center py-8 text-muted-foreground">
        No extractions found
      </div>
    );
  }

  return (
    <div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>File Name</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Tables</TableHead>
              <TableHead>Confidence</TableHead>
              <TableHead>Created</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {jobs.map(job => (
              <TableRow key={job.id}>
                <TableCell className="font-medium">{job.fileName}</TableCell>
                <TableCell>
                  <div className="flex flex-col gap-1">
                    {getStatusBadge(job.status)}
                    {job.errorMessage && (
                      <span className="text-xs text-red-500">
                        {job.errorMessage}
                      </span>
                    )}
                  </div>
                </TableCell>
                <TableCell>{job.tableCount || '-'}</TableCell>
                <TableCell>
                  {job.confidence
                    ? `${(job.confidence * 100).toFixed(1)}%`
                    : '-'}
                </TableCell>
                <TableCell>
                  {formatDistanceToNow(new Date(job.createdAt), {
                    addSuffix: true,
                  })}
                </TableCell>
                <TableCell className="text-right">
                  <div className="flex justify-end gap-2">
                    {job.previewUrl && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="outline"
                              size="icon"
                              onClick={() => setPreviewUrl(job.previewUrl!)}
                            >
                              <Eye className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>View preview</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                    {job.status === 'completed' && job.outputUrl && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="outline"
                              size="icon"
                              onClick={() => onDownload(job.id)}
                              disabled={isDownloading}
                            >
                              {isDownloading ? (
                                <Loader2 className="h-4 w-4 animate-spin" />
                              ) : (
                                <Download className="h-4 w-4" />
                              )}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>Download Excel</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="outline"
                            size="icon"
                            onClick={() => onDelete(job.id)}
                            disabled={isDeletingId === job.id}
                          >
                            {isDeletingId === job.id ? (
                              <Loader2 className="h-4 w-4 animate-spin" />
                            ) : (
                              <Trash2 className="h-4 w-4" />
                            )}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Delete</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div className="mt-4 flex justify-center">
        <SimplePagination
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={onPageChange}
        />
      </div>

      <Dialog open={!!previewUrl} onOpenChange={() => setPreviewUrl(null)}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Table Preview</DialogTitle>
          </DialogHeader>
          {previewUrl && (
            <div className="relative w-full h-[600px]">
              <img
                src={previewUrl}
                alt="Table preview"
                className="w-full h-full object-contain"
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
