import { Permission } from '@/lib/permissions';

export type UserRole = 'admin' | 'user';

export const USER_ROLES: UserRole[] = ['admin', 'user'];

export interface User {
  id: string;
  username: string;
  email: string;
  name: string;
  role: string;
  is_superadmin: boolean;
  tenant_id?: string;
  permissions?: Permission[];
  created_at?: string;
  updated_at?: string;
}

export interface UserFormData {
  username: string;
  email: string;
  password: string;
  name: string;
  role: UserRole;
  permissions: Permission[];
}

export interface UserUpdateData {
  id: number;
  username: string;
  email: string;
  name: string;
  role: UserRole;
  permissions: Permission[];
}

export interface PasswordUpdateData {
  id: number;
  password: string;
}

export interface UsersResponse {
  users: User[];
}

export interface ErrorResponse {
  details: string;
}
